import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Text, Box, HeadingText } from 'shared/components';
import { colors } from 'shared/utils';
import { Post } from '../types';

function PostThumbnail({ post }: { post: Post }) {
  return (
    <Link
      to={post.fields.slug}
      css={{
        boxShadow: `inset 0 -1px ${colors.gray}`,
        transition: 'all 0.2s cubic-bezier(0.76, 0, 0.24, 1)',
        ':hover,:focus': {
          boxShadow: `inset 0 -4px ${colors.blue}`,
        },
      }}
    >
      <Grid as="article" gridTemplateRows={[null, 'auto 8fr 1fr']} pb={6}>
        <Grid as="header" gridTemplateColumns="2fr 3fr" mb={3}>
          <Text as="time">{post.frontmatter.date}</Text>
          <Text
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {post.frontmatter.tags?.join(', ')}
          </Text>
        </Grid>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            border="1px solid"
            borderColor="gray"
          >
            <Img fluid={post.frontmatter.featuredImage?.childImageSharp.fluid} />
          </Box>
          <HeadingText mt={4}>{post.frontmatter.title || post.fields.slug}</HeadingText>
        </Box>
        <Box as="footer" mt={6} mr={10}>
          <Text>{post.frontmatter.authors?.join(', ')}</Text>
        </Box>
      </Grid>
    </Link>
  );
}

export default PostThumbnail;
