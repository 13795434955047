import React from 'react';
import { Grid } from 'shared/components';
import PostThumbnail from './PostThumbnail';
import { Post } from '../types';

type Props = {
  posts: { node: Post }[];
  gridTrack?: 'auto-fill' | 'auto-fit';
};

function PostsGrid({ posts = [], gridTrack = 'auto-fill' }: Props) {
  return (
    <Grid
      gridTemplateColumns={`repeat(${gridTrack}, minmax(305px, 1fr))`}
      gridRowGap={6}
      gridColumnGap={5}
      mt={6}
      px={6}
    >
      {posts.map(({ node: post }) => (
        <PostThumbnail key={post.fields.slug} post={post} />
      ))}
    </Grid>
  );
}

export default PostsGrid;
